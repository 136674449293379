<template>
  <div class="d-flex flex-column align-items-center layer">
    <MainBanner />
    <CharsDeck />
    <div class="max-w">
      <div class="secondary-text mt-3 text-center">
        Create detailed character profiles, build worlds, share your art, and collaborate with a vibrant community of
        creators just like you!
      </div>
      <div class="d-flex mt-4">
        <router-link to="signup" class="mx-auto"
          ><BigButton class="mx-auto" width="225px" height="72px" label="Sign Up" @click="toSignup"
        /></router-link>
      </div>
      <div class="login mt-4 text-center">
        Already have an account?
        <router-link to="login"> Log in here </router-link>
      </div>
      <div class="characters mt-5">
        <div class="title">Check out some Character Profiles:</div>
        <Characters />
      </div>
      <div class="art mt-5">
        <div class="title">Or some cool art!</div>
        <FanArt />
      </div>
      <div class="social-proof mt-5">
        <SocialProof class="mt-2 mb-5 mx-auto w-100" />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import BigButton from '@/shared/components/BigButton.vue';
const MainBanner = defineAsyncComponent(() => import('./MainBanner.vue'));
const CharsDeck = defineAsyncComponent(() => import('./CharsDeck.vue'));
const Characters = defineAsyncComponent(() => import('./Characters.vue'));
const FanArt = defineAsyncComponent(() => import('./FanArt.vue'));
const SocialProof = defineAsyncComponent(() => import('../SocialProof.vue'));
const toSignup = () => {
  const router = useRouter();
  router.push({ name: 'signup' });
};
</script>

<style lang="sass" scoped>
.layer
  z-index: 1
.max-w
  max-width: 690px
  margin: auto
  padding: 0 18px
  width: 100%
.title
  font-size: 20px
  font-weight: bold
</style>
